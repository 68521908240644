import React, { useState, useRef } from "react";
import SearchBar from "./SearchBar";
import { FaCalculator, FaPlus } from "react-icons/fa";
import { GiInjustice } from "react-icons/gi";
import { IoMenuSharp, IoClose } from "react-icons/io5";
import logo from "../assets/logo.png";
import { MenuItems, defSCPI } from "../constant/Menu";
import { useNavigate } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import useAuth from "./../hooks/useAuth";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logout } from "./../features/authSlice";

function Navbar() {
	const [recherche, setRecherche] = useState("");
	const [openMenu, setOpenMenu] = useState(false);
	const [openSubMenu, setOpenSubMenu] = useState(false);
	const { email } = useAuth();
	const dispatch = useDispatch();

	const childRef = useRef(null);
	const navigate = useNavigate();
	const handleOpenMenu = (e) => {
		if (childRef.current && e?.target === childRef.current) {
			setOpenMenu(false);
		}
	};
	const handleClick = (elem) => {
		if (elem === "Sociétés de gestion") {
			navigate("/organization");
			setOpenMenu(false);
		} else if (elem === "Sélection SCPI") {
			navigate("/scpis");
			setOpenMenu(false);
		} else if (elem === "Définition SCPI") {
			navigate("/");
		}
	};
	const handleLogout = () => {
		axios
			.post(
				"https://protailscpi.bacha-rabah.com/api/auth/logout",
				{},
				{ withCredentials: true }
			)
			.then((res) => dispatch(logout()))
			.catch((err) => console.log(err));
	};
	return (
		<header className="flex sticky z-30 bg-white top-0 right-0 justify-between items-center h-[50px] md:h-[80px] border-b  px-8 md:px-14">
			<img src={logo} alt="logo" className=" w-[200px] md:w-[300px]" />
			<div className="w-[250px] hidden lg:block">
				<SearchBar
					recherche={recherche}
					setRecherche={setRecherche}
					placeholder={"chercher une scpi ..."}
				/>
			</div>
			{email ? (
				<div
					className="text-red-500 cursor-pointer hidden lg:block "
					onClick={() => handleLogout()}>
					<FiLogIn className="text-2xl" />
					<p className="text-sm">LogOut</p>
				</div>
			) : (
				<div className="flex items-center justify-center space-x-4">
					<div className=" hidden lg:block  text-white w-[200px] font-medium">
						<div className="  bg-green-600 cursor-pointer hover:text-green-600 group hover:bg-white hover:border border-green-500  p-[2px] rounded-t-xl flex justify-start items-center bg-opacity-80 w-full space-x-2 ">
							<div className="p-1 rounded-full group-hover:bg-green-500 bg-white flex justify-center items-center ">
								<FaCalculator className="text-green-600 group-hover:text-white text-sm" />
							</div>
							<p className="text-sm tracking-wider ">
								SIMULATEUR
							</p>
						</div>
						<div className=" bg-yellow-500 cursor-pointer hover:text-yellow-500 group hover:bg-white hover:border border-yellow-500  p-[2px] rounded-b-xl flex justify-start items-center bg-opacity-80 w-full space-x-2">
							<div className="p-1 rounded-full bg-white group-hover:bg-yellow-500  flex justify-center items-center ">
								<GiInjustice className="text-yellow-500 group-hover:text-white text-sm" />
							</div>
							<p className="text-sm tracking-wider">
								COMPARATEUR
							</p>
						</div>
					</div>
					<div
						className="text-green-500 cursor-pointer hidden lg:block "
						onClick={() => navigate("/connexion")}>
						<FiLogIn className="text-2xl" />
						<p className="text-sm">Login</p>
					</div>
				</div>
			)}

			<div
				className="lg:hidden cursor-pointer"
				onClick={() => setOpenMenu(true)}>
				<IoMenuSharp className="text-4xl md:text-5xl " />
			</div>
			{openMenu && (
				<div
					onClick={(e) => handleOpenMenu(e)}
					ref={childRef}
					className="fixed lg:hidden top-0 right-0 w-full flex items-center justify-end  h-full bg-neutral-400 bg-opacity-50">
					<ul className="w-[300px] text-white h-full bg-neutral-900 flex flex-col  items-start justify-start">
						<div
							className="self-end bg-neutral-700 p-3 cursor-pointer hover:bg-white hover:text-black "
							onClick={() => setOpenMenu(false)}>
							<IoClose className="text-3xl" />
						</div>
						{MenuItems.map((item) => (
							<div
								onClick={() => {
									handleClick(item);
									setOpenMenu(false);
								}}
								key={item}
								className="text-lg text-neutral-200 border-b border-neutral-800   w-full">
								<li
									className="flex items-center px-3 cursor-pointer hover:border-l-[3px] py-2  hover:border-yellow-500  justify-between"
									key={item}>
									{item}
									{item === "Définition SCPI" && (
										<div
											className=""
											onClick={() => {
												setOpenSubMenu((prev) => !prev);
											}}>
											{!openSubMenu ? (
												<FaPlus />
											) : (
												<IoClose className="text-2xl font-medium" />
											)}
										</div>
									)}
								</li>
								{openSubMenu &&
									item === "Définition SCPI" &&
									defSCPI.map((elem) => (
										<p
											key={elem}
											className="py-1 border-b cursor-pointer hover:border-l-yellow-500 hover:border-l-[3px] text-base border-neutral-800 px-6 ">
											{elem}
										</p>
									))}
							</div>
						))}
						{email ? (
							<div
								className="hover:text-red-500 cursor-pointer  lg:hidden flex mt-12 py-1 px-2 rounded-sm mx-auto space-x-2 bg-red-500 hover:bg-white "
								onClick={() => handleLogout()}>
								<FiLogIn className="text-2xl hover:text-red-500" />
								<p className="text-sm">LogOut</p>
							</div>
						) : (
							<div
								className="hover:text-green-500 cursor-pointer  lg:hidden flex mt-12 py-1 px-2 rounded-sm mx-auto space-x-2 bg-green-500 hover:bg-white "
								onClick={() => navigate("/connexion")}>
								<FiLogIn className="text-2xl hover:text-green-500" />
								<p className="text-sm">LogOut</p>
							</div>
						)}
					</ul>
				</div>
			)}
		</header>
	);
}

export default Navbar;
