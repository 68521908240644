import React from "react";

import Hero from "../components/Hero";
import investirImg from "./../assets/investir.svg";
import { investir } from "../constant/Inputs";
import InformationSection from "../components/InformationSection";

function Investir() {
  return (
    <div className="lg:px-24 flex flex-col items-center justify-center">
      <Hero title={"Investir en"} image={investirImg} />
      <InformationSection
        title={"Qu’est-ce qu’un investissement en SCPI ?"}
        contenu={investir}
      />
    </div>
  );
}

export default Investir;
