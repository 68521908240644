import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

const useAuth = () => {
  const token = useSelector((state) => state.credential.token);

  if (token) {
    try {
      const decoded = jwtDecode(token);
      const { email, nom, prenom, id } = decoded.userInfo;

      return { email, nom, prenom, id };
    } catch (error) {
      // Handle decoding errors (e.g., invalid token format)
      console.error("Error decoding token:", error);
    }
  }

  return { email: "", nom: "", prenom: "", id: "" };
};

export default useAuth;
