import { useParams } from "react-router-dom";
import logo from "./../assets/societe.png";
import Scpi from "../components/Scpi";
import { useSelector } from "react-redux";

function Organization() {
	const { id } = useParams();

	const societe = useSelector((state) =>
		state.organisation.filter((elem) => elem._id === id)
	);

	return (
		<div className="py-6 px-4  ">
			<div className="flex flex-col justify-center items-center md:flex-row   ">
				<div className="w-full h-full rounded-md border shadow-xl py-12 px-10 text-blue-950 text-sm  flex flex-col justify-around items-center ">
					<h1 className="text-4xl md:pb-10 font-bold text-emerald-500 ">
						{societe[0]?.nomSociete}
					</h1>
					<img
						src={
							societe[0]?.avatar
								? `https://protailscpi.bacha-rabah.com/logos/${societe[0]?.avatar}`
								: logo
						}
						alt="logo"
						className="w-[130px] my-10 h-[130px] md:hidden"
					/>
					<p className="leading-6 md:leading-7 md:tracking-widest md:text-base  ">
						{societe[0]?.description}
					</p>
				</div>
				<div className=" flex flex-col justify-center w-[60%] md:w-[90%] md:ml-5 items-center pt-12 md:pt-0   ">
					<img
						src={
							societe[0]?.avatar
								? `https://protailscpi.bacha-rabah.com/logos/${societe[0]?.avatar}`
								: logo
						}
						alt="logo"
						className="w-[130px] hidden md:block my-10 md:my-3 h-[130px]"
					/>

					<div className="text-blue-900 w-full border flex items-center justify-around">
						<div className="flex w-full items-center border-r shadow-sm bg-neutral-100 justify-center py-2  font-bold ">
							<p className=" whitespace-nowrap text-sm">
								Nom de société
							</p>
						</div>
						<div className="flex items-center justify-center w-full ">
							<p className=" whitespace-nowrap text-sm ">
								{societe[0]?.nomSociete}
							</p>
						</div>
					</div>

					<div className="text-blue-900 w-full border flex items-center justify-around">
						<div className="flex w-full items-center border-r shadow-sm bg-neutral-100 justify-center py-2 font-bold ">
							<p className=" whitespace-nowrap text-sm">adress</p>
						</div>
						<div className="flex items-center justify-center w-full ">
							<p className=" whitespace-nowrap text-sm ">
								{societe[0]?.adress}{" "}
							</p>
						</div>
					</div>
					<div className="text-blue-900 w-full border flex items-center justify-around">
						<div className="flex w-full items-center border-r shadow-sm bg-neutral-100 justify-center py-2 font-bold ">
							<p className=" whitespace-nowrap text-sm">
								Création
							</p>
						</div>
						<div className="flex items-center justify-center w-full ">
							<p className=" whitespace-nowrap text-sm ">
								{societe[0]?.dateDeCreation}
							</p>
						</div>
					</div>
					<div className="text-blue-900 w-full border flex items-center justify-around">
						<div className="flex w-full items-center border-r shadow-sm bg-neutral-100 justify-center py-2  font-bold ">
							<p className=" whitespace-nowrap text-sm">
								Encours global (M£)
							</p>
						</div>
						<div className="flex items-center justify-center w-full ">
							<p className=" whitespace-nowrap text-sm ">
								{societe[0]?.encoursGlobal}
							</p>
						</div>
					</div>
					<div className="text-blue-900 w-full border flex items-center justify-around">
						<div className="flex w-full items-center border-r shadow-sm bg-neutral-100 justify-center py-2  font-bold ">
							<p className=" whitespace-nowrap text-sm">
								Nombre de fonds
							</p>
						</div>
						<div className="flex items-center justify-center w-full ">
							<p className=" whitespace-nowrap text-sm ">
								{societe[0]?.nbrFond}
							</p>
						</div>
					</div>
					<div className="text-blue-900 w-full border flex items-center justify-around">
						<div className="flex w-full items-center border-r shadow-sm bg-neutral-100 justify-center py-2  font-bold ">
							<p className=" whitespace-nowrap text-sm">
								Effectif
							</p>
						</div>
						<div className="flex items-center justify-center w-full ">
							<p className=" whitespace-nowrap text-sm ">
								{societe[0]?.effectif}
							</p>
						</div>
					</div>
					<div className="text-blue-900 w-full border flex items-center justify-around">
						<div className="flex w-full items-center border-r shadow-sm bg-neutral-100 justify-center py-2  font-bold ">
							<p className=" whitespace-nowrap text-sm">
								Actionnaire majoritaire
							</p>
						</div>
						<div className="flex items-center justify-center w-full ">
							<p className=" whitespace-nowrap text-sm ">
								{societe[0]?.actionaireMajouritaire}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="w-full  text-blue-900 py-12">
				<h1 className="text-3xl pb-6 tracking-wide">
					Les SCPI de{" "}
					<span className="font-bold">{societe[0]?.nomSociete}</span>
				</h1>
				<div className="flex justify-start  items-center  flex-wrap">
					{societe[0]?.scpi?.map((elem, index) => (
						<div key={elem._id} className="w-[270px] mb-2 ml-2">
							<Scpi elem={elem} index={index} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default Organization;
