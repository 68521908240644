import React from "react";
import frontimg from "./../assets/Front.svg";
import Hero from "../components/Hero";
import InformationSection from "../components/InformationSection";
import { contenu } from "./../constant/Inputs";

function Frontpage() {
  return (
    <div className="lg:px-24 flex flex-col items-center justify-center">
      <Hero title={"Définition et principe de la"} image={frontimg} />
      <InformationSection
        title={"qu’est-ce qu’une société civile de placement immobilier ?"}
        contenu={contenu}
      />
    </div>
  );
}

export default Frontpage;
