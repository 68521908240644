import { Outlet } from "react-router-dom";

import useAuth from "./useAuth";
import Connexion from "../pages/admin/AdminConnexion";

function AuthCheck() {
  const { email } = useAuth();
  return email ? <Outlet /> : <Connexion />;
}

export default AuthCheck;
