import { createSlice } from "@reduxjs/toolkit";
export const organizationSlice = createSlice({
  name: "organizations",
  initialState: [],
  reducers: {
    newOrganization: (state, action) => {
      return action.payload;
    },
    updateOrganization: (state, action) => {
      return state.map((elem) => {
        if (elem._id === action.payload._id) {
          return { ...elem, ...action.payload };
        } else {
          return elem;
        }
      });
    },
    deleteOrganization: (state, action) => {
      const array = state.filter((elem) => elem._id !== action.payload);

      return array;
    },
  },
});

export const { newOrganization, updateOrganization, deleteOrganization } =
  organizationSlice.actions;
export default organizationSlice.reducer;
