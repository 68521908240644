export const MenuItems = [
  "Définition SCPI",
  "Sociétés de gestion",

  "Sélection SCPI",
  "Investir en SCPI",
  "Actualité SCPI",
  "Qui sommes-nous",
];

export const defSCPI = [
  "SCPI de Rendement",
  "SCPI Fiscales",
  "SCPI de Plus-Value",
  "SCPI Européenne",
];
