import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
	Alert,
	FormControl,
	MenuItem,
	Select,
	Snackbar,
	Stack,
	TextField,
} from "@mui/material";
import { typeScpi, categorieScpi } from "../../constant/Inputs";
import axios from "axios";
import { updateOrganization } from "../../features/OrganizationSlice";
import { MdModeEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

function ScpiAdmin() {
	const { idOrganization, idScpi } = useParams();
	const organisations = useSelector((state) => state.organisation);
	const [organisation, setOrganisation] = useState([]);
	const [scpiUpdate, setScpiUpdate] = useState({});
	const dispatch = useDispatch();
	const [openSnack, setOpenSnack] = useState(false);
	const formik = useFormik({
		// Form validation
		initialValues: {
			titre: "",
			categorie: "",
			Type: "",
			gererPar: "",
			localisation: "",
			dateCrearion: "",
			tauxDistribution: "",
			tdAnnee: "",
			capitalisation: "",
			prixDepart: "",
			valeurRetrait: "",
			tof: "",
			assuranceVie: "",
		},
		onSubmit: (values) => {
			handleSubmit(values);
		},
		validationSchema: Yup.object({
			titre: Yup.string().required("Required"),
			categorie: Yup.string().required("Required"),
			Type: Yup.string().required("Required"),
			gererPar: Yup.string().required("Required"),
			localisation: Yup.string().required("Required"),
			dateCrearion: Yup.string().required("Required"),
			tauxDistribution: Yup.number().required("Required"),
			tdAnnee: Yup.number().required("Required"),
			capitalisation: Yup.number().required("Required"),
			prixDepart: Yup.number().required("Required"),
			valeurRetrait: Yup.number().required("Required"),
			tof: Yup.number().required("Required"),
			assuranceVie: Yup.boolean().required("Required"),
		}),
	});
	const handleSubmit = (values) => {
		if (idScpi) {
			const newScpi = organisation.scpi.map((elem) => {
				if (elem._id === idScpi) {
					return { _id: idScpi, ...values };
				} else {
					return elem;
				}
			});

			axios
				.patch(
					`https://protailscpi.bacha-rabah.com/api/scpi/${idScpi}`,
					{
						...values,
					}
				)
				.then((res) => {
					dispatch(
						updateOrganization({
							...organisation,
							scpi: newScpi,
						})
					);
					setOpenSnack(true);
				})
				.catch((err) => console.log("err", err));
		} else {
			axios
				.post(
					`https://protailscpi.bacha-rabah.com/api/scpi/${idOrganization}`,
					{
						...values,
					}
				)
				.then((res) => {
					const array = [...organisation?.scpi];
					array?.push({
						_id: res?.data?._id,
						titre: res?.data?.titre,
						categorie: res?.data?.categorie,
						Type: res?.data?.Type,
						gererPar: res?.data?.gererPar,
						localisation: res?.data?.localisation,
						dateCrearion: res?.data?.dateCrearion,
						tauxDistribution: res?.data?.tauxDistribution,
						tdAnnee: res?.data?.tdAnnee,
						capitalisation: res?.data?.capitalisation,
						prixDepart: res?.data?.prixDepart,
						valeurRetrait: res?.data?.valeurRetrait,
						tof: res?.data?.tof,
						assuranceVie: res?.data?.assuranceVie,
					});

					dispatch(
						updateOrganization({
							...organisation,
							scpi: array,
						})
					);
					setOpenSnack(true);
					formik.setValues(formik.initialValues);
					formik.setErrors(formik.initialErrors);
					formik.setTouched(formik.initialTouched);
				});
		}
	};

	useEffect(() => {
		if (idOrganization) {
			const array = organisations.filter(
				(elem) => elem._id === idOrganization
			);
			setOrganisation(array && array[0]);
			if (idScpi) {
				const scpiArray = array[0]?.scpi?.filter(
					(ele) => ele._id === idScpi
				);
				setScpiUpdate(scpiArray && scpiArray[0]);
			}
		}
	}, [idOrganization, idScpi, organisations]);

	useEffect(() => {
		if (scpiUpdate) {
			formik.setValues({
				titre: scpiUpdate.titre,
				categorie: scpiUpdate.categorie,
				Type: scpiUpdate.Type,
				gererPar: scpiUpdate.gererPar,
				localisation: scpiUpdate.localisation,
				dateCrearion: scpiUpdate.dateCrearion,
				tauxDistribution: scpiUpdate.tauxDistribution,
				tdAnnee: scpiUpdate.tdAnnee,
				capitalisation: scpiUpdate.capitalisation,
				prixDepart: scpiUpdate.prixDepart,
				valeurRetrait: scpiUpdate.valeurRetrait,
				tof: scpiUpdate.tof,
				assuranceVie: scpiUpdate.assuranceVie,
			});
		}
	}, [organisation]);

	return (
		<div className="px-2">
			<div className="text-2xl text-blue-950 font-semibold  py-12 flex justify-center items-center">
				{idScpi ? <p>Mettre à jour la Scpi</p> : <p>Crée une Scpi</p>}
			</div>
			<form
				onSubmit={formik.handleSubmit}
				className="grid md:grid-cols-2 items-start gap-5 py-12 ">
				<div className="flex flex-col  justify-center items-center space-y-5">
					<TextField
						fullWidth
						size="small"
						error={formik.errors.titre && formik.touched.titre}
						id="outlined-error"
						label="Nom de la Scpi"
						values={formik.values.titre}
						{...formik.getFieldProps("titre")}
						helperText={formik.errors.titre}
					/>
					<FormControl size="small" fullWidth>
						<p className="px-1 text-sm pb-1">Type de la Scpi</p>
						<Select
							value={formik.values.Type}
							size="small"
							onChange={(e) => {
								formik.setValues({
									...formik.values,
									Type: e.target.value,
								});
							}}>
							{typeScpi.map((elem) => (
								<MenuItem key={elem} value={elem}>
									{elem}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl size="small" fullWidth>
						<p className="px-1 text-sm pb-1">
							Catégorie de la Scpi
						</p>
						<Select
							value={formik.values.categorie}
							size="small"
							onChange={(e) => {
								formik.setValues({
									...formik.values,
									categorie: e.target.value,
								});
							}}>
							{categorieScpi.map((elem) => (
								<MenuItem key={elem} value={elem}>
									{elem}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.gererPar && formik.touched.gererPar
						}
						id="outlined-error"
						label="Gérer par"
						values={formik.values.gererPar}
						{...formik.getFieldProps("gererPar")}
						helperText={formik.errors.gererPar}
					/>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.localisation &&
							formik.touched.localisation
						}
						id="outlined-error"
						label="Localisation de la Scpi"
						values={formik.values.localisation}
						{...formik.getFieldProps("localisation")}
						helperText={formik.errors.localisation}
					/>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.dateCrearion &&
							formik.touched.dateCrearion
						}
						id="outlined-error"
						label="Date de Création"
						values={formik.values.dateCrearion}
						{...formik.getFieldProps("dateCrearion")}
						helperText={formik.errors.dateCrearion}
					/>
				</div>
				<div className="flex flex-col justify-center items-center space-y-5 pt-5 md:pt-0">
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.tauxDistribution &&
							formik.touched.tauxDistribution
						}
						id="outlined-error"
						label="Taux de Distribution"
						values={formik.values.tauxDistribution}
						{...formik.getFieldProps("tauxDistribution")}
						helperText={formik.errors.tauxDistribution}
					/>
					<TextField
						fullWidth
						size="small"
						error={formik.errors.tdAnnee && formik.touched.tdAnnee}
						id="outlined-error"
						label="TD 2022"
						values={formik.values.tdAnnee}
						{...formik.getFieldProps("tdAnnee")}
						helperText={formik.errors.tdAnnee}
					/>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.prixDepart &&
							formik.touched.prixDepart
						}
						id="outlined-error"
						label="Prix de la part en 2023"
						values={formik.values.prixDepart}
						{...formik.getFieldProps("prixDepart")}
						helperText={formik.errors.prixDepart}
					/>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.valeurRetrait &&
							formik.touched.valeurRetrait
						}
						id="outlined-error"
						label="Valeur de retrait au 30-09-2023"
						type="number"
						values={formik.values.valeurRetrait}
						{...formik.getFieldProps("valeurRetrait")}
						helperText={formik.errors.valeurRetrait}
					/>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.capitalisation &&
							formik.touched.capitalisation
						}
						id="outlined-error"
						label="Capitalisation"
						values={formik.values.capitalisation}
						{...formik.getFieldProps("capitalisation")}
						helperText={formik.errors.capitalisation}
					/>
					<TextField
						fullWidth
						size="small"
						error={formik.errors.tof && formik.touched.tof}
						id="outlined-error"
						label="Taux d'occupation financier 2022"
						values={formik.values.tof}
						{...formik.getFieldProps("tof")}
						helperText={formik.errors.tof}
					/>
					<FormControl size="small" fullWidth>
						<p className="px-1 text-sm pb-1">Assurance vie</p>
						<Select
							value={formik.values.assuranceVie}
							size="small"
							onChange={(e) => {
								formik.setValues({
									...formik.values,
									assuranceVie: e.target.value,
								});
							}}>
							<MenuItem value={true}>oui</MenuItem>
							<MenuItem value={false}>non</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className="  ">
					<Stack spacing={2} sx={{ width: "100%" }}>
						<Snackbar
							open={openSnack}
							autoHideDuration={3000}
							onClose={() => setOpenSnack(false)}>
							<Alert
								onClose={() => setOpenSnack(false)}
								severity="success"
								sx={{ width: "100%" }}>
								{idScpi
									? "Mis à jour avec succées"
									: "Ajouter avec succées"}
							</Alert>
						</Snackbar>
					</Stack>
					<button
						type="submit"
						className="flex text-white font-medium px-3 py-1 rounded-sm border border-blue-600 hover:text-blue-600 hover:bg-white  justify-center items-center space-x-1 bg-blue-600">
						{idScpi ? <MdModeEdit /> : <FaPlus />}
						{idScpi ? <p>Mettre a jour</p> : <p>Créer Scpi</p>}
					</button>
				</div>
			</form>
		</div>
	);
}

export default ScpiAdmin;
