import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import admin from "../../assets/admin.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/authSlice";

function Connexion() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const onSubmitLogin = () => {
		axios
			.post(
				"https://protailscpi.bacha-rabah.com/api/auth",
				{ email, password },
				{ withCredentials: true }
			)
			.then((res) => {
				dispatch(setCredentials(res.data));
				navigate("/");
			})
			.catch((err) =>
				setError("Mot de passe ou adresse e-mail incorrecte")
			);
	};

	return (
		<div className="w-[350px] m-auto mt-[50px] border rounded shadow-md  space-y-4 p-[23px]  ">
			<div className="m-auto">
				{" "}
				<img src={admin} className="m-auto w-48" alt="admin" />
			</div>
			<h1 className="text-blue-700 text-lg text-center">
				Interface Administrateur
			</h1>
			<div className="flex flex-col space-y-4">
				<TextField
					className="flex-1  "
					value={email}
					id="demo-helper-text-misaligned"
					label="E-mail"
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					className="flex-1  "
					value={password}
					id="demo-helper-text-misaligned"
					label="Password"
					type="password"
					onChange={(e) => setPassword(e.target.value)}
				/>
			</div>

			<div>
				<button
					className="bg-indigo-700 hover:text-indigo-700 hover:bg-white border border-indigo-700 rounded-2xl py-1 px-5 text-white ml-[80px] mt-2"
					onClick={() => {
						onSubmitLogin();
					}}>
					{" "}
					Se Connecter{" "}
				</button>
				<p className="text-red-500  text-center">{error}</p>
			</div>
		</div>
	);
}

export default Connexion;
