import React from "react";
import { FaCalendarAlt, FaPhoneAlt } from "react-icons/fa";
import { BsFillSendFill } from "react-icons/bs";

function SubFooter() {
  return (
    <div className="py-12  px-6 bg-emerald-50 w-full">
      <h1 className="text-3xl leading-relaxed text-center md:text-start font-bold text-emerald-500">
        Parlons ensemble de votre projet
      </h1>
      <p className="text-center md:text-start pt-3 text-gray-800 text:sm md:text-base  ">
        Vous avez besoin d'aide, de conseils, ou tout simplement de réponses à
        vos questions ?
      </p>
      <p className="text-center md:text-start text-gray-800 text:sm md:text-base  ">
        Un conseiller dédié est disponible du{" "}
        <span>lundi au vendredi de 9h à 20h.</span>
      </p>
      <div className="px-12 grid md:grid-cols-2 lg:grid-cols-3   space-y-3 md:space-y-0 pt-5 gap-3">
        <div className="flex shadow-md justify-start items-center bg-white rounded-md py-2 px-3 space-x-2 cursor-pointer">
          <div className="bg-emerald-200 rounded-full p-3">
            <FaPhoneAlt className="text-emerald-500 text-3xl" />
          </div>
          <div>
            <p className=" text-emerald-500 font-bold text-xl">0674404760</p>
            <p className="text-gray-500 text-sm">appel non surtaxé</p>
          </div>
        </div>
        <div className="flex shadow-md justify-start items-center bg-white rounded-md py-2 px-3 space-x-2 cursor-pointer">
          <div className="bg-emerald-200 rounded-full p-3">
            <FaCalendarAlt className="text-emerald-500 text-3xl" />
          </div>
          <p className=" text-emerald-500 font-bold text-xl">
            Prenez rendez-vous
          </p>
        </div>
        <div className="flex shadow-md justify-start items-center bg-white rounded-md py-2 px-3 space-x-2 cursor-pointer ">
          <div className="bg-emerald-200 rounded-full p-3">
            <BsFillSendFill className="text-emerald-500 text-3xl" />
          </div>
          <p className=" text-emerald-500 font-bold text-xl">
            Envoyez un message
          </p>
        </div>
      </div>
    </div>
  );
}

export default SubFooter;
