import React from "react";

function FooterSection({ data }) {
  return (
    <div className=" flex flex-col items-center justify-center">
      <div className="py-8 border-b w-full flex  items-center justify-center">
        <h1 className="text-3xl font-bold">{data.titre}</h1>
      </div>
      <ul className="border-b flex flex-col items-center justify-center w-full py-4 font-medium space-y-4 text-sm">
        {data.data.map((elem) => (
          <li className="hover:underline cursor-pointer" key={elem}>
            {elem}
          </li>
        ))}
      </ul>
      <ul className=" flex flex-col items-center justify-center w-full py-4  text-sm">
        {data.subSection.map((elem) => (
          <li
            key={elem.titre}
            className="flex flex-col items-center justify-center  space-y-4 ">
            <h2 className="font-medium">{elem.titre}</h2>
            <p className="text-center">{elem.data}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FooterSection;
