import React from "react";
import { scpiData, aideData, outilsData } from "../constant/footer";
import FooterSection from "./FooterSection";
import { IoMdStar } from "react-icons/io";

function Footer() {
  return (
    <div className="flex flex-col text-neutral-100 bg-zinc-800 w-full justify-center items-center ">
      <div className="   grid md:gap-20 px-28 md:px-20 md:grid-cols-3 items-center md:items-start border-b border-zinc-600 ">
        <FooterSection data={scpiData} />
        <FooterSection data={outilsData} />
        <FooterSection data={aideData} />
      </div>
      <div className="py-5 flex items-center space-x-2 ">
        <p className="hidden md:block font-medium">Excellent</p>
        <div className="flex space-x-1">
          <div className="p-1 bg-green-700 cursor-pointer">
            <IoMdStar className="text-lg" />
          </div>
          <div className="p-1 bg-green-700 cursor-pointer">
            <IoMdStar className="text-lg" />
          </div>
          <div className="p-1 bg-green-700 cursor-pointer">
            <IoMdStar className="text-lg" />
          </div>
          <div className="p-1 bg-green-700 cursor-pointer">
            <IoMdStar className="text-lg" />
          </div>
          <div className="p-1 bg-green-700 cursor-pointer">
            <IoMdStar className="text-lg" />
          </div>
        </div>
        <p className="text-sm hidden lg:block">4.9 sur 5 basé sur 179 avis</p>

        <div className="flex items-center px-1 ">
          <IoMdStar className="text-xl text-green-700" />{" "}
          <p className="text-sm font-medium">Trustpilot</p>
        </div>
      </div>
      <p className="text-center text-sm pb-5">
        <span className="cursor-pointer hover:underline hover:text-yellow-500">
          Portail-SCPI.fr
        </span>{" "}
        2023 - Marque déposée de la société Fortuny -{" "}
        <span className="cursor-pointer hover:underline hover:text-yellow-500">
          Mentions légales
        </span>{" "}
        -{" "}
        <span className="cursor-pointer hover:underline hover:text-yellow-500">
          Politique de protection des données personnelles
        </span>
      </p>
    </div>
  );
}

export default Footer;
