import React from "react";

function InformationSection({ title, contenu }) {
  return (
    <div className=" mx-3 my-8 border rounded-3xl p-3 text-blue-950  ">
      {title ===
        "qu’est-ce qu’une société civile de placement immobilier ?" && (
        <h1 className="text-2xl font-bold text-center leading-relaxed pb-2">
          SCPI définition :
        </h1>
      )}
      <h1 className="text-2xl font-bold text-center leading-relaxed pb-4">
        {title}
      </h1>
      <ul className="flex flex-col items-center justify-center space-y-3">
        {contenu.map((elem) => (
          <li key={elem} className="text-center md:text-start  ">
            {elem}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default InformationSection;
