import React from "react";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newOrganization } from "../features/OrganizationSlice";
import axios from "axios";

function PrefetchOrganization() {
	const dispatch = useDispatch();
	const organisations = useSelector((state) => state?.organisation);

	useEffect(() => {
		const getOrganizations = () => {
			axios
				.get("https://protailscpi.bacha-rabah.com/api/organization")
				.then((res) => {
					dispatch(newOrganization(res?.data));
				})
				.catch((err) => console.log("err", err));
		};
		if (!organisations?.length) getOrganizations();
	}, [organisations, dispatch]);

	return <Outlet />;
}

export default PrefetchOrganization;
