import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, Snackbar, Stack, TextField } from "@mui/material";

import { BiImageAdd } from "react-icons/bi";
import Scpi from "../../components/Scpi";
import { MdModeEdit } from "react-icons/md";
import axios from "axios";
import {
	newOrganization,
	updateOrganization,
} from "../../features/OrganizationSlice";
import { FaPlus } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";

function OrganizationAdmin() {
	const { id } = useParams();
	const organisations = useSelector((state) => state.organisation);
	const [organisation, setOrganisation] = useState([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { email } = useAuth();
	const div1 = useParams(null);
	const [avatar, setAvatar] = useState();
	const [imagePreviewUrl, setImagePreviewUrl] = useState("");
	const [openSnack, setOpenSnack] = useState(false);

	const formik = useFormik({
		// Form validation
		initialValues: {
			nomSociete: "",
			adress: "",
			dateDeCreation: "",
			encoursGlobal: "",
			nbrFond: "",
			effectif: "",
			actionaireMajouritaire: "",
			description: "",
			scpis: [],
		},
		onSubmit: (values) => {
			handleSubmit(values);
		},
		validationSchema: Yup.object({
			nomSociete: Yup.string().required("Required"),
			adress: Yup.string().required("Required"),
			dateDeCreation: Yup.string().required("Required"),
			encoursGlobal: Yup.number().required("Required"),
			nbrFond: Yup.number().required("Required"),
			effectif: Yup.string().required("Required"),
			actionaireMajouritaire: Yup.string().required("Required"),
			description: Yup.string().required("Required"),
		}),
	});

	const handleSubmit = (values) => {
		if (id) {
			console.log(" { ...values, avatar }", { ...values, avatar });
			axios
				.patch(
					`https://protailscpi.bacha-rabah.com/api/organization/${id}`,
					{ ...values, avatar },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				)
				.then((res) => {
					dispatch(
						updateOrganization({
							...organisation,
							...values,
							avatar: res.data.avatar,
						})
					);
					setOpenSnack(true);
				})
				.catch((err) => console.log("err", err));
		} else {
			axios
				.post(
					`https://protailscpi.bacha-rabah.com/api/organization`,
					{ ...values, avatar },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				)
				.then((res) => {
					dispatch(
						newOrganization([
							...organisations,
							{
								_id: res.data._id,
								nomSociete: res.data.nomSociete,
								adress: res.data.adress,
								dateDeCreation: res.data.dateDeCreation,
								encoursGlobal: res.data.encoursGlobal,
								nbrFond: res.data.nbrFond,
								effectif: res.data.effectif,
								actionaireMajouritaire:
									res.data.actionaireMajouritaire,
								description: res.data.description,
								scpis: [],
								avatar: res.data.avatar,
							},
						])
					);
					setOpenSnack(true);
					formik.setValues(formik.initialValues);
					formik.setErrors(formik.initialErrors);
					formik.setTouched(formik.initialTouched);
					setImagePreviewUrl("");
					setAvatar(null);
				});
		}
	};

	const uploadImage = (image) => {
		const file = image.target.files[0];
		setAvatar(file);
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImagePreviewUrl(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};
	useEffect(() => {
		if (id) {
			const array = organisations.filter((elem) => elem._id === id);
			setOrganisation(array[0]);
		}
	}, [id, organisations]);

	useEffect(() => {
		if (organisation) {
			formik.setValues({
				nomSociete: organisation.nomSociete,
				adress: organisation.adress,
				dateDeCreation: organisation.dateDeCreation,
				encoursGlobal: organisation.encoursGlobal,
				nbrFond: organisation.nbrFond,
				effectif: organisation.effectif,
				actionaireMajouritaire: organisation.actionaireMajouritaire,
				description: organisation.description,
				scpis: organisation.scpis,
			});
			if (organisation.avatar) {
				setImagePreviewUrl(
					`https://portailscpi.bacha-rabah.com/logos/${organisation?.avatar}`
				);
			}
		}
	}, [organisation]);

	return (
		<div className="px-2">
			<div className="text-2xl text-blue-950 font-semibold  py-12 flex justify-center items-center">
				{id ? (
					<p>Mettre à jour la Société</p>
				) : (
					<p>Crée une Organisation</p>
				)}
			</div>
			<form
				onSubmit={formik.handleSubmit}
				className="grid md:grid-cols-2 items-end gap-5 py-12 ">
				<div className="flex flex-col  justify-center items-center space-y-5">
					<div>
						<div
							className={`${
								imagePreviewUrl
									? " w-[200px] h-[200px]"
									: "border-2 w-[200px] h-[200px] border-black"
							} rounded-md cursor-pointer relative   border-black my-5 `}
							onClick={() =>
								div1.current ? div1?.current?.click() : ""
							}>
							{imagePreviewUrl ? (
								<img
									src={imagePreviewUrl}
									alt={"preview"}
									className="w-full h-full "
								/>
							) : (
								<BiImageAdd className=" absolute right-2 top-2 text-2xl " />
							)}
						</div>
						<input
							type="file"
							name="avatar"
							multiple
							hidden
							ref={div1}
							onChange={(e) => uploadImage(e)}
						/>
					</div>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.nomSociete &&
							formik.touched.nomSociete
						}
						id="outlined-error"
						label="Nom de Société"
						values={formik.values.nomSociete}
						{...formik.getFieldProps("nomSociete")}
						helperText={formik.errors.nomSociete}
					/>
					<TextField
						fullWidth
						multiline
						rows={8}
						size="small"
						error={
							formik.errors.description &&
							formik.touched.description
						}
						id="outlined-error"
						label="Déscription"
						values={formik.values.description}
						{...formik.getFieldProps("description")}
						helperText={formik.errors.description}
					/>
				</div>
				<div className="flex flex-col justify-center items-center space-y-5 pt-5">
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.actionaireMajouritaire &&
							formik.touched.actionaireMajouritaire
						}
						id="outlined-error"
						label="Actionnaire Majoritaire"
						values={formik.values.actionaireMajouritaire}
						{...formik.getFieldProps("actionaireMajouritaire")}
						helperText={formik.errors.actionaireMajouritaire}
					/>
					<TextField
						fullWidth
						size="small"
						error={formik.errors.adress && formik.touched.adress}
						id="outlined-error"
						label="Adress"
						values={formik.values.adress}
						{...formik.getFieldProps("adress")}
						helperText={formik.errors.adress}
					/>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.dateDeCreation &&
							formik.touched.dateDeCreation
						}
						id="outlined-error"
						label="Date de Création"
						values={formik.values.dateDeCreation}
						{...formik.getFieldProps("dateDeCreation")}
						helperText={formik.errors.dateDeCreation}
					/>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.effectif && formik.touched.effectif
						}
						id="outlined-error"
						label="Effectifs"
						type="number"
						values={formik.values.effectif}
						{...formik.getFieldProps("effectif")}
						helperText={formik.errors.effectif}
					/>
					<TextField
						fullWidth
						size="small"
						error={formik.errors.nbrFond && formik.touched.nbrFond}
						id="outlined-error"
						label="Nombre de fonds"
						values={formik.values.nbrFond}
						{...formik.getFieldProps("nbrFond")}
						helperText={formik.errors.nbrFond}
					/>
					<TextField
						fullWidth
						size="small"
						error={
							formik.errors.encoursGlobal &&
							formik.touched.encoursGlobal
						}
						id="outlined-error"
						label="Encours Globale"
						values={formik.values.encoursGlobal}
						{...formik.getFieldProps("encoursGlobal")}
						helperText={formik.errors.encoursGlobal}
					/>
				</div>
				<div className="  ">
					<button
						type="submit"
						className="flex text-white font-medium px-3 py-1 rounded-sm border border-blue-600 hover:text-blue-600 hover:bg-white  justify-center items-center space-x-1 bg-blue-600">
						{id ? <MdModeEdit /> : <FaPlus />}
						{id ? (
							<p>Mettre a jour</p>
						) : (
							<p>Créer l'Organisation</p>
						)}
					</button>
				</div>
			</form>

			{id && (
				<div className="w-full  text-blue-900 py-12">
					<h1 className="text-3xl pb-6 tracking-wide">
						Les SCPI de{" "}
						<span className="font-bold">
							{organisation?.nomSociete}
						</span>
					</h1>
					<Stack spacing={2} sx={{ width: "100%" }}>
						<Snackbar
							open={openSnack}
							autoHideDuration={3000}
							onClose={() => setOpenSnack(false)}>
							<Alert
								onClose={() => setOpenSnack(false)}
								severity="success"
								sx={{ width: "100%" }}>
								{id
									? "Mis à jour avec succées"
									: "Ajouter avec succées"}
							</Alert>
						</Snackbar>
					</Stack>
					{email && (
						<button
							onClick={() =>
								navigate(`/scpiAdmin/${organisation._id}`)
							}
							className="font-medium mx-4 mb-3 text-white flex justify-center items-center px-2 space-x-2 border-blue-500 border hover:text-blue-500 hover:bg-white rounded-sm bg-blue-500">
							<FaPlus />
							<p>Ajouter une Scpi</p>
						</button>
					)}
					{organisation?.scpi && (
						<div className="flex justify-start  items-center  flex-wrap">
							{organisation?.scpi?.map((elem, index) => (
								<div
									key={elem._id}
									className="w-[270px] mb-2 ml-2">
									<Scpi
										elem={elem}
										organisation={organisation}
										index={index}
									/>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default OrganizationAdmin;
