import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

function SearchBar({ setRecherche, recherche, placeholder }) {
  const [focus, setFocus] = useState(false);
  return (
    <div
      className={`${
        focus ? " border-yellow-400" : "border-gray-300 "
      } w-full px-2 flex justify-start items-center border-2 rounded-full`}>
      <FaSearch className={`${focus ? "text-yellow-400" : " text-gray-400"}`} />
      <input
        value={recherche}
        onChange={(e) => setRecherche(e.target.value)}
        placeholder={placeholder}
        className=" outline-none text-gray-500 w-full font-medium bg-transparent py-1 px-2"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  );
}

export default SearchBar;
