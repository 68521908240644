import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./../components/Navbar";
import MenuLg from "./../components/MenuLg";
import Footer from "../components/Footer";
import SubFooter from "../components/SubFooter";

function Layout() {
	return (
		<div>
			<Navbar />
			<MenuLg />
			<Outlet />
			<SubFooter />
			<Footer />
		</div>
	);
}

export default Layout;
