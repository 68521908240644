import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateOrganization } from "../features/OrganizationSlice";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

function Scpi({ elem, index, organisation }) {
	const [openModal, setOpenModal] = useState(false);
	const { email } = useAuth();
	const dispatch = useDispatch();
	const styles = [
		"bg-gray-300 text-indigo-900",
		"bg-blue-900 text-white",
		"bg-green-400 text-white",
		"bg-yellow-500 text-indigo-900",
	];

	const style = [
		"bg-gray-100",
		"bg-blue-700",
		"bg-green-200",
		"bg-yellow-300",
	];
	const navigate = useNavigate();

	const handleDelete = (id) => {
		if (organisation) {
			const scpisUpdated = organisation.scpi.filter(
				(scpi) => scpi?._id !== elem?._id
			);

			axios
				.delete(
					`https://protailscpi.bacha-rabah.com/api/scpi/${organisation._id}/${elem._id}`
				)
				.then((res) => {
					dispatch(
						updateOrganization({
							...organisation,
							scpi: scpisUpdated,
						})
					);
				})
				.catch((err) => console.log("err", err));
		}
	};
	const handleClick = () => {
		email
			? organisation
				? navigate(`/scpiAdmin/${organisation._id}/${elem._id}`)
				: navigate(`/scpi/${elem._id}`)
			: navigate(`/scpi/${elem._id}`);
	};

	return (
		<div className="relative">
			{email && (
				<div
					onClick={() => setOpenModal(true)}
					className="absolute cursor-pointer top-2 bg-red-500 z-20 flex hover:scale-110 justify-center items-center right-2 rounded-full p-1 shadow-sm">
					<IoMdClose className="text-xl text-white" />
				</div>
			)}
			<div
				key={elem._id}
				onClick={() => handleClick()}
				className={`${
					styles[index % styles.length]
				} hover:shadow-md rounded-xl group w-full mr-2 mb-3 p-4 cursor-pointer`}>
				<h1 className="text-xl  font-bold">{elem.titre}</h1>
				<p>
					Gérée par <span>{elem.gererPar}</span>
				</p>

				<div
					className={` ${
						style[index % styles.length]
					} group-hover:shadow-md  flex w-full mt-4   justify-around items-center  bg-opacity-20 group-hover:bg-opacity-50 rounded-lg group-hover:rounded-full `}>
					<div className=" flex flex-col  justify-center items-center">
						<p className="text-sm">TD 2022</p>
						<p className="text-2xl font-bold">{elem.tdAnnee}</p>
					</div>

					<div className=" flex flex-col justify-center items-center">
						<p className="text-sm">Prix de part</p>
						<p className="text-2xl font-bold">{elem.prixDepart}</p>
					</div>
				</div>
			</div>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				center
				animationDuration={400}
				styles={{
					overlay: { opacity: 10 },
					modal: { borderRadius: 10 },
				}}>
				<div className="space-y-4 text-center">
					<p className="text-lg font-medium text-gray-900">Scpi</p>
					<div className="border-t"></div>
					<p className="text-gray-800 ">
						{" "}
						Voulez-vous vraiment supprimmer cette Scpi ?
					</p>

					<div className="border-t"></div>
					<div className="flex justify-between">
						<button
							className="text-ray-900 text-md font-semibold underline  "
							onClick={() => {
								setOpenModal(false);
							}}>
							Annuler
						</button>
						<button
							onClick={() => handleDelete()}
							className="text-white bg-blue-600 px-6 py-2 rounded-xl hover:bg-blue-500">
							Confirmer
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default Scpi;
