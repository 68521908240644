export const typeScpi = ["rendement", "plus-value"];
export const categorieScpi = [
  "Bureau",
  "Commerce",
  "Diversifiée",
  "Immobilier résidentiel",
  "Logistique",
  "Spécialisée",
  "ISR",
];

export const contenu = [
  "La SCPI (Société Civile de Placement Immobilier), aussi appelée pierre papier, est un véhicule d’investissement immobilier non coté qui a pour principal objectif l’acquisition et la gestion d’actifs immobiliers appartenant à divers secteurs. Il s’agit d’un placement collectif dans lequel les épargnants peuvent investir à travers l’achat de parts, et ainsi devenir associés.",
  "En investissant dans la pierre papier, les souscripteurs deviennent en quelque sorte copropriétaires, avec les autres porteurs de parts, des biens immobiliers locatifs détenus par la SCPI. Les investisseurs n’ont toutefois pas à s’inquiéter de la gestion. Les nombreux actifs sont de manière générale loués à des entreprises, qui sont régulièrement de grands groupes nationaux ou internationaux. ",
  "La SCPI perçoit ainsi des loyers, qui sont en partie redistribués aux investisseurs particuliers sous forme de revenus locatifs appelés dividendes. La distribution se fait au prorata du nombre de parts sociales détenues. ",
  "La société civile de placement immobilier est donc un investissement de long terme dont le but consiste à distribuer un revenu régulier (non garanti) à ses associés. Accessible à la fois aux particuliers mais aussi aux professionnels (personnes morales de type associations), ce placement immobilier existe depuis plus de 50 ans et se démocratise de plus en plus. ",
  "Les épargnants ont la possibilité d’investir en SCPI au comptant, en s’appuyant uniquement sur leurs liquidités. Toutefois, la pierre papier est également accessible via un contrat d’assurance vie, à crédit, ou encore en démembrement de propriété.",
  "Il existe plusieurs familles de SCPI selon leur fonctionnement et leur objectif : SCPI de rendement, SCPI fiscales et SCPI de plus-value. Puis, il existe différents secteurs d’investissement. La stratégie de chaque véhicule détermine dans quelle catégorie il se trouve. Certaines sociétés civiles de placement immobilier choisissent de se concentrer sur un seul secteur (SCPI spécialisées) tandis que d’autres misent sur la diversification (SCPI diversifiées). ",
];
export const investir = [
  "Investir en SCPI (Société Civile de Placement Immobilier) offre à l’épargnant de nombreuses possibilités et de belles perspectives de rendement sur le long terme. Pour fonctionner, la SCPI collecte des capitaux auprès des investisseurs et les réinvestit ensuite dans des actifs immobiliers qu’elle loue pour générer du rendement. Ainsi, les investisseurs deviennent associés porteurs des parts de la SCPI et, en contrepartie de leur investissement, ils perçoivent des revenus locatifs réguliers, tirés des loyers collectés par la SCPI, en proportion de la quotepart du capital de la société qu’ils détiennent.",
  "Comment investir en SCPI ? L’épargnant doit acquérir des parts au sein de la société et, pour cela, divers méthodes de financement s’offrent à lui : au comptant, à crédit, via l’assurance vie, ou encore en démembrement.",
  "L’investissement en SCPI n’est pas gratuit. L’investisseur devra compter certains frais tels que des frais de souscription, compris dans le prix de souscription de la part et prélevés uniquement à la sortie, ou encore des frais de gestion.",
];
