import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import { lazy, Suspense } from "react";

import Connexion from "./pages/admin/AdminConnexion";
// import Organizations from "./pages/Organizations";
import Organization from "./pages/Organization";

import Scpi from "./pages/Scpi";
// import Scpis from "./pages/Scpis";
import PrefetckOrganization from "./hooks/PrefetchOrganization";
import PersisteLogin from "./hooks/PersisteLogin";
import OrganizationAdmin from "./pages/admin/OrganizationAdmin";
import AuthCheck from "./hooks/AuthCheck";
import ScpiAdmin from "./pages/admin/ScpiAdmin";
import FallbackLoading from "./loader/FallbackLoading";
import Frontpage from "./pages/Frontpage";
import Investir from "./pages/Investir";

function App() {
  const Scpis = lazy(() => import("./pages/Scpis"));
  const Organizations = lazy(() => import("./pages/Organizations"));
  return (
    <BrowserRouter>
      <Suspense fallback={<FallbackLoading />}>
        <Routes>
          <Route path="/connexion" element={<Connexion />} />

          <Route element={<Layout />}>
            <Route element={<PrefetckOrganization />}>
              <Route element={<PersisteLogin />}>
                <Route path="/scpis" element={<Scpis />} />
                <Route path="/scpi/:id" element={<Scpi />} />
                <Route path="/organization" element={<Organizations />} />
                <Route path="/" element={<Frontpage />} />
                <Route path="/investir" element={<Investir />} />
                <Route path="/organization/:id" element={<Organization />} />
                <Route element={<AuthCheck />}>
                  <Route
                    path="/organizationAdmin/:id?"
                    element={<OrganizationAdmin />}
                  />
                  <Route path="/scpiAdmin" element={<OrganizationAdmin />} />
                  <Route
                    path="/scpiAdmin/:idOrganization/:idScpi?"
                    element={<ScpiAdmin />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
