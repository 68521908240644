import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaCheck, FaCalendarAlt, FaMoneyBillWaveAlt } from "react-icons/fa";
import { AiOutlineStock, AiFillEuroCircle } from "react-icons/ai";
function Scpi() {
	const { id } = useParams();
	const [scpi, setScpi] = useState({});

	useEffect(() => {
		axios
			.get(`https://protailscpi.bacha-rabah.com/api/scpi/${id}`)
			.then((res) => setScpi(res.data))
			.catch((err) => console.log("err", err));
	}, [id]);

	return (
		<div>
			<div className="text-blue-950 px-4 py-6  bg-gradient-to-bl  from-white to-yellow-200">
				<h1 className="text-3xl font-bold  tracking-wide">
					{scpi.titre}
				</h1>
				<p className="py-2">
					SCPI {scpi.categorie} gerée par{" "}
					<span className="text-green-600">{scpi.gererPar}</span>
				</p>
				<div className="flex justify-start items-center space-x-4 flex-wrap">
					<p className="py-0.5 font-medium text-sm bg-gradient-to-tr from-yellow-400 bg-opacity-60 to-yellow-200 rounded-md w-fit px-2">
						{" "}
						SCPI de {scpi.categorie}
					</p>
					<p className="py-0.5 font-medium text-sm bg-gradient-to-tr from-green-300 bg-opacity-60 rounded-md to-green-100 w-fit px-2">
						{" "}
						SCPI de {scpi.categorie}
					</p>
					<div className="py-0.5 flex items-center justify-center space-x-2 font-medium text-sm bg-gradient-to-tr from-yellow-400 bg-opacity-60 to-yellow-200 rounded-md  w-fit px-2">
						<FaCheck /> <p>Démembrement</p>
					</div>
				</div>
			</div>
			<div className="grid md:grid-cols-2  lg:grid-cols-3 text-blue-950 px-5 gap-3 py-2">
				<div className="md:col-span-2 lg:my-5  lg:col-span-1 lg:row-span-3 flex flex-col items-center justify-center  py-10 shadow-lg rounded-md">
					<div className="flex text-5xl font-bold space-x-2  items-center justify-center">
						<AiOutlineStock className="text-yellow-500" />
						<p>{scpi.tauxDistribution}%</p>
					</div>
					<p className=" font-medium text-neutral-500">
						Taux de Distribution{" "}
						<span className="font-bold">2022</span>
					</p>
				</div>
				<div className=" md:row-span-2 md:flex md:py-5 lg:py-3 md:flex-col md:justify-center ">
					<div className="flex md:flex-1 justify-start items-center space-x-2 px-3 shadow-lg rounded-md py-5">
						<AiFillEuroCircle className="text-5xl text-yellow-400" />
						<div className="flex flex-col justify-center items-start">
							<p className="text-2xl font-bold">
								{scpi.prixDepart},00 $
							</p>
							<p className="  text-neutral-500">
								Prix de la part en 2023
							</p>
						</div>
					</div>
					<div className="flex md:flex-1 justify-start items-center space-x-2 px-3 shadow-lg rounded-md py-5">
						<AiFillEuroCircle className="text-5xl text-yellow-400" />
						<div className="flex flex-col justify-center items-start">
							<p className="text-2xl font-bold">
								{scpi.valeurRetrait} $
							</p>
							<p className="  text-neutral-500">
								Valeur de retrait au 30-09-2023
							</p>
						</div>
					</div>
				</div>
				<div>
					<div className="flex justify-start items-center space-x-2 px-3 shadow-lg rounded-md py-5">
						<FaMoneyBillWaveAlt className="text-5xl text-green-400" />
						<div className="flex flex-col justify-center items-start">
							<p className="text-2xl font-bold">
								{scpi.capitalisation}M$
							</p>
							<p className="  text-neutral-500">Capitalisation</p>
						</div>
					</div>
					<div className="flex justify-start items-center space-x-2 px-3 shadow-lg rounded-md py-5">
						<AiOutlineStock className="text-blue-500 text-5xl" />

						<div className="flex flex-col justify-center items-start">
							<p className="text-2xl font-bold">{scpi.tof} %</p>
							<p className="  text-neutral-500">
								Taux d'occupation financier 2022
							</p>
						</div>
					</div>
					<div className="flex justify-start items-center space-x-2 px-3 shadow-lg rounded-md py-5">
						<FaCalendarAlt className="text-5xl text-red-400" />
						<div className="flex flex-col justify-center items-start">
							<p className="text-2xl font-bold">
								{scpi.dateCrearion}
							</p>
							<p className="  text-neutral-500">
								Année de création
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Scpi;
