import { configureStore } from "@reduxjs/toolkit";

import credentialReducer from "../features/authSlice";
import organizationReducer from "../features/OrganizationSlice";

export const store = configureStore({
  reducer: {
    credential: credentialReducer,
    organisation: organizationReducer,
  },
});
