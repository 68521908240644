import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../features/authSlice";
import axios from "axios";
function PersisteLogin() {
	const dispatch = useDispatch();

	const token = useSelector((state) => state?.credential?.token);

	useEffect(() => {
		const verifyRefreshToken = () => {
			const axiosInstance = axios.create({
				baseURL: "https://protailscpi.bacha-rabah.com/api/auth/refresh",
				withCredentials: true,
			});
			axiosInstance
				.get()
				.then((res) => {
					dispatch(setCredentials(res?.data));
				})
				.catch((err) => console.log("err", err));
		};
		if (!token) verifyRefreshToken();
	}, [token, dispatch]);

	return <Outlet />;
}

export default PersisteLogin;
