import React from "react";
import { MenuItems } from "../constant/Menu";
import { useNavigate } from "react-router-dom";

function MenuLg() {
	const navigate = useNavigate();
	const handleClick = (elem) => {
		if (elem === "Sociétés de gestion") {
			navigate("/organization");
		} else if (elem === "Sélection SCPI") {
			navigate("/scpis");
		} else if (elem === "Définition SCPI") {
			navigate("/");
		} else if (elem === "Investir en SCPI") {
			navigate("/investir");
		}
	};
	return (
		<nav className="hidden lg:block">
			<ul className="flex relative items-center justify-center  border-y border-gray-100  ">
				{MenuItems.map((elem) => (
					<li
						onClick={() => handleClick(elem)}
						key={elem}
						className="border-x p-4 border-gray-100 text-gray-600 tracking-wider cursor-pointer hover:text-yellow-400 ">
						{elem}
					</li>
				))}
			</ul>
		</nav>
	);
}

export default MenuLg;
