import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";

function Hero({ title, image }) {
  return (
    <div className="px-12 w-full py-12 md:flex items-center justify-start md:px-8">
      <div className=" text-blue-950 flex md:justify-start md:items-start justify-center items-center flex-col">
        <h1 className="text-5xl  bg-opacity-10 to-white font-[550] text-center md:text-start py-3 leading-relaxed ">
          {title}
          <span className="font-bold text-emerald-500"> SCPI</span>
        </h1>
        {title === "Investir en" ? (
          <p className="text-xl font-medium">
            4 méthodes{" "}
            <span className="font-bold">d’investissement en SCPI</span>
          </p>
        ) : (
          <p className="text-xl font-medium">
            Comprendre <span className="font-bold">La SCPI</span> avant
            d’investir
          </p>
        )}

        <div className="py-5 flex lg:flex-row lg:items-center lg:space-x-3  items-center justify-center md:items-start flex-col">
          <button className=" my-5 flex justify-center items-center space-x-2 py-2 px-12 bg-emerald-600 rounded-full font-medium text-white hover:scale-105">
            <p>Simulez votre invistissement </p>
            <FaChevronRight />
          </button>
          <p className="underline font-medium cursor-pointer">
            Prenez RDV avec un expert
          </p>
        </div>
        <div className="pt-5 self-center">
          <p className="font-medium text-sm pb-2">
            Plus de 5 000 clients nous ont fait confiance.
          </p>
          <div className=" flex items-center space-x-2  ">
            <p className="hidden md:block font-medium">Excellent</p>
            <div className="flex space-x-1 text-white">
              <div className="p-1 bg-green-700 cursor-pointer">
                <IoMdStar className="" />
              </div>
              <div className="p-1 bg-green-700 cursor-pointer">
                <IoMdStar className="" />
              </div>
              <div className="p-1 bg-green-700 cursor-pointer">
                <IoMdStar className="" />
              </div>
              <div className="p-1 bg-green-700 cursor-pointer">
                <IoMdStar className="" />
              </div>
              <div className="p-1 bg-green-700 cursor-pointer">
                <IoMdStar className="" />
              </div>
            </div>

            <div className="flex items-center px-1 ">
              <IoMdStar className="text-xl text-green-700" />{" "}
              <p className="text-sm font-medium">Trustpilot</p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[500px] lg:w-[600px]">
        <img src={image} alt="background" />
      </div>
    </div>
  );
}

export default Hero;
