export const scpiData = {
  titre: "Scpi",
  data: ["SCPI", "Définition SCPI", "Comment souscrire ?", "SCPI de rendement"],
  subSection: [
    {
      titre: "Adresses FORTUNY",
      data: "33 rue Fortuny 75017 Paris 131 Av CDG 92200 Neuilly 18 rue Paul Vidal, Toulouse",
    },
  ],
};

export const outilsData = {
  titre: "OUTILS",
  data: [
    "Simulateur SCPI",
    "Meilleures SCPI",
    "Comparateur SCPI",
    "Guide SCPI",
  ],
  subSection: [
    {
      titre: "Adresse e-Mail",
      data: "contact@portail-scpi.fr",
    },
    {
      titre: "Téléphone",
      data: "Paris : 01 44 09 87 13 Toulouse : 05 34 66 85 16",
    },
  ],
};

export const aideData = {
  titre: "AIDE",
  data: [
    "Qui sommes nous ?",
    "Nous contacter",
    "Revue de presse",
    "Prendre RDV",
  ],
  subSection: [
    {
      titre: "Membre Anacofi-CIF – Carte T",
      data: "1092 N 957",
    },
    {
      titre: "ORIAS / CIF",
      data: "200 090 87 / E009507",
    },
  ],
};
